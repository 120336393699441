.btn {
  // height: 40px;
  // border: 0;
  border-radius: 0;
  // padding: 10px 50px;
  text-transform: uppercase;

  &:active,
  &.active {
    box-shadow: none;
  }

  &:active,
  &:hover,
  &:focus {
    outline: 0 !important;
    outline-offset: 0;
  }

  // & {
  //     background-color: #69c;
  // }

  // &,
  // &:hover,
  // &:focus {
  //     color: #fff;
  // }
}

// .btn-default {
//     @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
// }
// .btn-primary {
//     @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
// }
// // Success appears as green
// .btn-success {
//     @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
// }
// // Info appears as blue-green
// .btn-info {
//     @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
// }
// // Warning appears as orange
// .btn-warning {
//     @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
// }
// // Danger and error appear as red
// .btn-danger {
//     @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
// }

.btn:not([disabled]) {
  overflow: hidden;
  position: relative;
  z-index: 0;

  &::after {
    content: ' ';
    position: absolute;
    width: 150%;
    height: 100%;
    top: 0;
    left: 0;
    transition: transform 600ms;
    background-color: rgba(0, 0, 0, 0.1);
    transform: translateX(-200%) skewX(124deg) scaleX(2);
  }

  &:hover::after {
    transform: translateX(-15%) skewX(124deg) scaleX(2);
  }
}

.btn-link {
  text-decoration: $link-hover-decoration
    lighten($color: $link-hover-color, $amount: 50);
}
