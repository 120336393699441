.alert {
  border-left-width: 5px;
}

.alert-success {
  border-left-color: $alert-success-text;
  // @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
}

.alert-info {
  border-left-color: $alert-info-text;
}

.alert-warning {
  border-left-color: $alert-warning-text;
}

.alert-danger {
  border-left-color: $alert-danger-text;
}
