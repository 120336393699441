html,
body {
  font-size: 15px;
  // overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: #fff;
  font-family: 'LatoWeb', Helvetica, sans-serif;
  // padding-top: 50px;
}

// a,
// a:hover,
// a:active {
//     color: #333;
// }

p {
  line-height: 1.5625;
}

figure {
  margin-top: 7px;

  &.pull-left {
    margin-right: 30px;
  }

  &.pull-right {
    margin-left: 30px;
  }
}

blockquote {
  font-size: 1em;
  font-style: italic;
  margin-bottom: 6px;
  border: 0;
  padding: 0;

  &::before,
  &::after {
    display: block;
    color: $brand-danger;
    font-family: 'FontAwesome';
    font-size: 1em;
    font-style: normal;
    text-align: center;
  }

  &::before {
    content: '\f10d';
    padding-bottom: 9px;
  }

  &::after {
    content: '\f10e';
    padding-top: 12px;
  }
}

.read-more {
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none !important;
  border-bottom: 1px solid;
  padding-top: 0.1875em;
  padding-bottom: 1px;
  line-height: 1;
  font-size: 16px;
}
