.form-group {
  outline: 0;
}

.form-control {
  margin-bottom: 30px;
  // border: 1px solid #036d94;
  border-radius: 0;
  box-shadow: none;
  padding-right: 15px;
  padding-left: 15px;
  color: $input-color;
  // font-size: $font-size-base;
  height: 2.5em;
  font-size: 1.066667em;

  &:focus {
    outline: 0;
    // border-color: #036d94;
    box-shadow: none;
  }

  &.checkbox,
  &.checkbox-inline {
    border: none;
    background-color: transparent;
  }
}

.input-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  // border-radius: 3px;
}

.input-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  // border-radius: 6px;
}

.help-block {
  margin-top: -25px;
}
