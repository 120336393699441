.rw-widget-container {
  border-radius: 0;
  outline: 0;
}
.rw-widget-container.rw-state-focus,
.rw-widget-container.rw-state-focus:hover,
.rw-state-focus > .rw-widget-container,
.rw-state-focus > .rw-widget-container:hover {
  background-color: $input-bg; /*#fff;*/
  border-color: $input-border-focus; /*#66afe9;*/
  /*box-shadow: 0 0 8px rgba(102,175,233,0.6);*/
  @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.075));
}

.form-control {
  &.rw-combobox,
  &.rw-dropdown-list,
  &.rw-widget {
    // overflow: hidden;
    box-sizing: content-box;
    padding: 0;

    .rw-widget-picker,
    .rw-widget-container {
      // height: 100%;
      border: none;
    }

    .rw-popup {
      border-radius: 0px;
    }
  }
}
