@import '../fonts/Lato/latofonts.css';
@import '../styles/settings.scss';
@import '~font-awesome/css/font-awesome.css';

// $icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
@import '~bootstrap-sass/assets/stylesheets/_bootstrap.scss';

@import '~react-toastify/dist/ReactToastify.css';
@import '~react-dates/lib/css/_datepicker.css';
@import '~react-widgets/dist/css/react-widgets.css';

@import '../styles/overrides/style';
@import '../styles/template/style';
