// react-bootstrap

// $link-color: $brand-primary !default;
// //** Link hover color set via `darken()` function.
// $link-hover-color: darken($link-color, 15%) !default;
// //** Link hover decoration.
// $link-hover-decoration: underline !default;
$color1: rgb(17, 17, 17);
$color2: #999;

$modal-sm: 325px;

$border-radius-large: 0;

// panels
$panel-border-radius: 0;

// $font-size-base: 1.066667em;
$input-border: #036d94;
$input-color: #333;
$input-border-radius: 0;
$input-border-focus: #036d94;

// $brand-primary:         darken(#428bca, 6.5%) !default; // #337ab7
$brand-primary: #333; // #337ab7
// $brand-primary: red; // #337ab7
// $brand-success:         #5cb85c !default;
$brand-info: #69c;
// $brand-warning:         #f0ad4e !default;
$brand-danger: #a2231d;

// navbar
$navbar-default-bg: transparentize(#ebebeb, 0.5);
$navbar-default-border: transparentize(darken(#ebebeb, 10%), 0.5);
$navbar-height: 50px;

$navbar-inverse-bg: rgba($color1, 0.75);
$navbar-inverse-border: rgba(lighten($color1, 10%), 0.75);

$table-border-color: $color1;

$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';

@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables';

@import 'mixins/index';
