@import '../../../styles/settings.scss';

.table {
  border-top-color: $btn-info-border;

  thead {
    tr {
      th {
        border-right: 1px solid $btn-info-border;
        border-left: 1px solid $btn-info-border;
        text-align: center;
        background-color: $btn-info-bg;
        color: $btn-info-color;
        font-weight: normal;
      }
    }
  }
}
