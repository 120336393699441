.DateInput_input {
  // font-weight: 200;
  // font-size: 19px;
  // line-height: 24px;
  // color: #484848;
  // background-color: #fff;
  padding: 6px 11px;
}

.DateInput_input__focused {
  border-bottom: 2px solid $brand-info;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  // background: #00a699;
  // border: 1px double #00a699;
  background: $brand-info;
  border: 1px double $brand-info;
  color: #fff;
}

.CalendarDay__selected_span {
  // background: #66e2da;
  // border: 1px double #33dacd;
  background: lighten($color: $brand-info, $amount: 20);
  border: 1px double lighten($color: $brand-info, $amount: 10);
  color: #fff;

  &:active,
  &:hover {
    // background: #33dacd;
    // border: 1px double #33dacd;
    background: lighten($color: $brand-info, $amount: 10);
    border: 1px double lighten($color: $brand-info, $amount: 10);
    color: #fff;
  }
}
